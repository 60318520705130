import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AddressService } from "../../services/shared/address-service";
import { AddressAutoCompleteActions, AddressAutoCompleteApiActions } from "../actions/address.action";
import { catchError, concatMap, map } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class AddressEffects {

    autoComplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddressAutoCompleteActions.addressAutoComplete),
            concatMap(({ searchTerm }) =>
                this._addressService.placeAutoComplete(searchTerm).pipe(
                    map((placeAutoComplete) =>
                        AddressAutoCompleteApiActions.addressAutoCompleteSuccess({ placeAutoComplete: placeAutoComplete})),
                    catchError((error: HttpErrorResponse) => [AddressAutoCompleteApiActions.addressAutoCompleteFail({ message: error.message })])
                )
            )))

    address$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddressAutoCompleteActions.addressGeoCode),
            concatMap(({ geocode }) =>
                this._addressService.placeGeoCode(geocode).pipe(
                    map((address) => AddressAutoCompleteApiActions.addressGeoCodeSuccess({ address: address }),
                        catchError((error: HttpErrorResponse) => [AddressAutoCompleteApiActions.addressGeoCodeFail({ message: error.message })])
                    )
                ))))

    constructor(
        private actions$: Actions,
        private _addressService: AddressService
    ) {
    }

}