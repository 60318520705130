import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { EmailService } from "../../services/shared/email-service";
import { EmailVerificationAction, EmailVerificationActionAPI } from "../actions/email.action";

@Injectable()
export class EmailEffects {

    email$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailVerificationAction.emailVerification),
            concatMap(({ emailAddress, index }) =>
                this._emailService.verifyEmail(emailAddress, index).pipe(
                    map((email) => EmailVerificationActionAPI.emailVerificationSuccess({ email: email })),
                    catchError((error: HttpErrorResponse) => {
                        return [EmailVerificationActionAPI.emailVerificationFail({ message: error.statusText })]
                    })
                )
            )))

    emails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailVerificationAction.emailsVerification),
            concatMap(({ emails }) =>
                this._emailService.verifyEmails(emails).pipe(
                    map((emails) => EmailVerificationActionAPI.emailsVerificationSuccess({ emails: emails })),
                    catchError((error: HttpErrorResponse) => {
                        return [EmailVerificationActionAPI.emailsVerificationFail({ message: error.statusText })]
                    })
                )
            )))

    emailsSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailVerificationAction.siteEmailsVerification),
            concatMap(({ emails }) =>
                this._emailService.verifyEmails(emails).pipe(
                    map((emails) => EmailVerificationActionAPI.siteEmailsVerificationSuccess({ emails: emails })),
                    catchError((error: HttpErrorResponse) => {
                        return [EmailVerificationActionAPI.siteEmailsVerificationFail({ message: error.statusText })]
                    })
                )
            )))

    emailsEmployee$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailVerificationAction.employeeEmailsVerification),
            concatMap(({ emails }) =>
                this._emailService.verifyEmails(emails).pipe(
                    map((emails) => EmailVerificationActionAPI.employeeEmailsVerificationSuccess({ emails: emails })),
                    catchError((error: HttpErrorResponse) => {
                        return [EmailVerificationActionAPI.employeeEmailsVerificationFail({ message: error.statusText })]
                    })
                )
            )))


    constructor(
        private actions$: Actions,
        private _emailService: EmailService
    ) {
    }

}