import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { EmailService } from "../../services/shared/email-service";
import { EmailVerificationAction, EmailVerificationActionAPI } from "../actions/email.action";

@Injectable()
export class EmailEffects {

    email$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailVerificationAction.emailVerification),
            concatMap(({ emailAddress }) =>
                this._emailService.verifyEmail(emailAddress).pipe(
                    map((email) =>{
                       const resultObject = JSON.parse(JSON.stringify(email))
                       let host = resultObject.result.host;
                       if( resultObject.result?.suggestion){
                        host = resultObject.result.suggestion;
                       }
                       const emailAddress = resultObject.result.local +"@"+  host
                       const resultParsed = {"email" : emailAddress , "verdict": resultObject.result.verdict}
                       if(resultParsed.verdict != "Valid") {
                         throw new HttpErrorResponse({statusText : resultParsed.email + " not valid!"})
                       }
                       return  EmailVerificationActionAPI.emailVerificationSuccess({ email: resultParsed})
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return [EmailVerificationActionAPI.emailVerificationFail({ message: error.statusText})]
                    })
                )
            )))

    constructor(
        private actions$: Actions,
        private _emailService: EmailService
    ) {
    }

}