import { CommonModule } from '@angular/common';
import { Component, OnInit, effect, input, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SplashScreenPageActions } from '../ngrx-state/actions/splashscreen.action';

@Component({
    selector: 'splash-screen',
    standalone: true,
    templateUrl: './splashscreen.component.html',
    styleUrls: ['./splashscreen.component.scss'],
    imports: [CommonModule]
})
export class SplashScreenComponent implements OnInit {
   
    constructor(
        private _store: Store,
        private _router: Router
    ) {

    }
    showSplash = signal(true);
    ngOnInit(): void {        
        setTimeout(() => {
            this.showSplash.set(false)
        }, 9000);   
    }

}