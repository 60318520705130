import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit, input } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import { SplashScreenComponent } from './splashscreen/splashscreen.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import { Store } from '@ngrx/store';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { map, startWith } from 'rxjs';
import { NewMenuComponent } from './menu/new-menu.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgIf, MatButtonModule, MatIconModule, MatSidenavModule, MatListModule, MatInputModule, 
           MatToolbarModule, MatFormFieldModule, RouterOutlet, RouterLink, RouterLinkActive, SplashScreenComponent, 
           AsyncPipe, ReactiveFormsModule, NewMenuComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  autoCompleteFormControl = new FormControl('')
  constructor(
    private _store: Store,
    private _activatedRoute: ActivatedRoute
  ) {
    
  }

  ngOnInit(): void {
    //this._store.dispatch(GeolocationPermissionActions.geoLocationPermissionPrompt())
  }
}
