import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects"
import { SkillService } from "../../services/skill-service";
import { SkillsAPIActions, SkillsPageActions } from "../actions/skill.action";
import { catchError, concatMap, map, mergeMap } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class SkillEffects {

    skills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SkillsPageActions.loadSkills),
            mergeMap(() =>
                this._skillService.skills$.pipe(
                    map((skills) => SkillsAPIActions.skillsLoadedSuccess({ skills })),
                    catchError((error: HttpErrorResponse) => [SkillsAPIActions.skillsLoadedFail({ message: String(error.error) })])
                )
            )
        )
    )

    skillAdded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SkillsPageActions.addSkill),
            concatMap(({ skill }) =>
                this._skillService.saveSkill(skill).pipe(
                    map((newSkill) =>
                        SkillsAPIActions.skillAddedSuccess({ skill: newSkill })),
                    catchError((error: HttpErrorResponse) => [SkillsAPIActions.skillAddedFail({ message: String(error.error) })])
                )
            )
        ))

    skillsAdded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SkillsPageActions.addSkills),
            concatMap(({ skills }) =>
                this._skillService.saveSkills(skills).pipe(
                    map((skills) =>
                        SkillsAPIActions.skillsAddedSuccess({ skills: skills })),
                    catchError((error: HttpErrorResponse) => [SkillsAPIActions.skillsAddedFail({ message: String(error.error) })])
                )
            )
        ))

    skillUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SkillsPageActions.updateSkill),
            concatMap(({ skill }) =>
                this._skillService.updateSkill(skill).pipe(
                    map((updatedSkill) =>
                        SkillsAPIActions.skillUpdatedSuccess({ skill: updatedSkill })),
                    catchError((error: HttpErrorResponse) => [SkillsAPIActions.skillAddedFail({ message: String(error.error) })])
                )
            )
        )
    )

    skillDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SkillsPageActions.deleteSkill),
            mergeMap(action =>
                this._skillService.deleteSkill(action.skillId).pipe(
                    map((deleted) => {
                        if (deleted) { return SkillsAPIActions.skillDeletedSuccess({ skillId: action.skillId }) }
                        return SkillsAPIActions.skillDeletedFail({ message: 'Unable to delete Skill with Id: ' + action.skillId })
                    }),
                    catchError((error: HttpErrorResponse) => [SkillsAPIActions.skillDeletedFail({ message: String(error.error) })])
                )
            )
        )
    )

    constructor(
        private actions$: Actions,
        private _skillService: SkillService) {

    }
}