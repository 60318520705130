import { createReducer, on } from "@ngrx/store";
import { EmailVerificationAction, EmailVerificationActionAPI } from "../actions/email.action";

export interface IResultEmailState{
    email: any,
    loading: boolean,
    errorMessage: string,
}

export const initialState : IResultEmailState = {
    email: {},
    loading:false,
    errorMessage: ''
}

export const emailReducer = createReducer(
    initialState,
    on(EmailVerificationAction.emailVerification, (state, {emailAddress})=>({
        ...state,
        loading:true,
        errorMessage: ''
    })),
    on(EmailVerificationActionAPI.emailVerificationSuccess, (state, {email})=>({
        ...state,
        loading:false,
        email: email,
        errorMessage: ''
    })),
    on(EmailVerificationActionAPI.emailVerificationFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    }))
)