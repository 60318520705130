import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialog, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { SkillNewComponent } from "../skills/skill-new/skill.new.component";

@Component({
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: true,
    imports: [MatChipsModule, MatIconModule, CommonModule, MatDialogClose, MatButtonModule, MatDialogContent]
})
export class MenuComponent {
    menus: any[] = [{ name: 'ADD SKILL', color: 'accent', link: './skills/new', img: '../../assets/img/security.svg' },
    { name: 'ADD EMPLOYEE', color: 'primary', link: './employee/new', img: '../../assets/img/badge.svg' },
    { name: 'ADD SITE', color: 'accent', link: './site/new', img: '../../assets/img/captive_portal.svg' },
    { name: 'ADD USER', color: 'primary', link: './user/new', img: '../../assets/img/person_add.svg' }]
    constructor(
        private dialogRef: MatDialogRef<MenuComponent>,
        public dialog: MatDialog,
        private _router: Router
    ) {
        const route = this._router.url;
        if (route.includes("skill")) {
            this.dialogRef.close();
            this.dialog.open(SkillNewComponent, {
                data: { id: '', name: '', description: '' },
            });
        }
        else if (route.includes("employee")) {
            this.dialogRef.close();
            this._router.navigate(['./employee/new'])
        }
        else if (route.includes("site")) {
            this.dialogRef.close();
            this._router.navigate(['./site/new'])
            
        }
        else if (route.includes("user")) {
            this.dialogRef.close();
            this._router.navigate(['./user/new'])
        }
    }
    onAddClick(menu: any) {
        this.dialogRef.close();
        this._router.navigate([menu.link])

    }
}