import { Component, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import {MatCheckboxModule} from "@angular/material/checkbox"
import {
    MatDialog,
    MatDialogRef,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
  } from '@angular/material/dialog';
import { MenuComponent } from "./menu.component";

@Component({
    selector : 'new-menu',
    templateUrl: './new-menu.component.html',
    styleUrls: ['./new-menu.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule,MatCheckboxModule, SweetAlert2Module]

})
export class NewMenuComponent implements OnInit{

    
    constructor(        
        public dialog: MatDialog
    ) {
        
    }
    ngOnInit(): void {
        
    }

    onOpenMenuDialog(enterAnimationDuration: string, exitAnimationDuration: string){
        this.dialog.open(MenuComponent, {
            width: '250px',
            enterAnimationDuration,
            exitAnimationDuration,
          });
    }

  
}