import { createReducer, on } from "@ngrx/store";
import { PhoneVerificationAPIActions, PhoneVerificationActions } from "../actions/phone.action";

export interface IResultPhoneState{
    phone: any,
    loading: boolean,
    errorMessage: string,
}

export const initialState : IResultPhoneState = {
    phone: {},
    loading:false,
    errorMessage: ''
}

export const phoneReducer = createReducer(
    initialState,
    on(PhoneVerificationActions.phoneNumberVerification, (state)=>({
        ...state,
        loading:true,
        errorMessage: ''
    })),
    on(PhoneVerificationAPIActions.phoneVerificationSuccess, (state, {phone})=>({
        ...state,
        loading:false,
        phone: phone,
        errorMessage: ''
    })),
    on(PhoneVerificationAPIActions.phoneVerificationFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    }))
)