<div class="container">
    <form>
      <h2 mat-dialog-title>New Skill</h2>
       <mat-dialog-content>
            <div class="col-auto">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="data.name" name="name">
                </mat-form-field>
            </div>
            <div class="col-auto">
                <mat-form-field>
                    <mat-label>Description</mat-label>
                    <input matInput [(ngModel)]="data.description" name="description">
                </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button (click)="onSaveSkill(data)">SAVE</button>
            <button mat-button (click)="onNoClick()">CANCEL</button>
        </mat-dialog-actions>
    </form>
</div>

