import { createReducer, on } from "@ngrx/store";
import { SplashScreenPageActions } from "../actions/splashscreen.action";

export interface IEnforceState{
    showSplashScreen: boolean
}
export const initialState : IEnforceState ={
    showSplashScreen: false,
}

export const splashScreenReducer = createReducer(
    initialState,
    on(SplashScreenPageActions.showSplashScreen, (state)=>({
        ...state,
        showSplashScreen: true,
    })),
    on(SplashScreenPageActions.hideSplashScreen, (state)=>({
        ...state,
        showSplashScreen: false
    }))
)   