import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { map, of, tap } from "rxjs";
import { IAddress } from "../../models/address";
import { IPlaceAutoComplete } from "../../models/placeAutoComplete";

@Injectable({
    providedIn: 'root'
})
export class PhoneService {

    private teamAdvanceCommunicationApi = environment.teamAdvanceCommunicationApi;
    private options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), params: new HttpParams(), webFetchExtra: { mode: 'no-cors' }  };
    constructor(private _httpClient: HttpClient) {
    }

    verifyPhoneNumber(phoneNumber: string) {
        return this._httpClient.get<any>(this.teamAdvanceCommunicationApi + "v1/sms/check/" + phoneNumber, this.options);
    }



}