import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideHttpClient, withFetch, withInterceptors, withJsonpSupport } from '@angular/common/http';
import { AuthenticationService } from './services/shared/auth-token-service';
import { environment } from '../environments/environment';
import { geoLocationReducer } from './ngrx-state/reducers/geolocation.reducer';
import { GeoLocationEffects } from './ngrx-state/effects/geolocation.effects';
import { GeoLocationService } from './services/shared/geolocation-service';
import { CustomHeaderInceptor } from './services/shared/custom-interceptor';
import { splashScreenReducer } from './ngrx-state/reducers/splashscreen.reducer';
import { provideNativeDateAdapter } from '@angular/material/core';
import { skillsReducer } from './ngrx-state/reducers/skill.reducer';
import { SkillEffects } from './ngrx-state/effects/skill.effects';
import { AddressService } from './services/shared/address-service';
import { addressReducer } from './ngrx-state/reducers/address.reducer';
import { AddressEffects } from './ngrx-state/effects/address.effects';
import { employeeReducer } from './ngrx-state/reducers/employee.reducer';
import { EmployeeEffects } from './ngrx-state/effects/employee.efffects';
import { ApplicationInsightsService } from './services/shared/application-insights';
import { EmailService } from './services/shared/email-service';
import { PhoneService } from './services/shared/phone-service';
import { PhoneEffects } from './ngrx-state/effects/phone.effects';
import { EmailEffects } from './ngrx-state/effects/email.effects';
import { phoneReducer } from './ngrx-state/reducers/phone.reducer';
import { emailReducer } from './ngrx-state/reducers/email.reducer';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideClientHydration(), provideAnimationsAsync(), 
              provideStore({'enforce': splashScreenReducer, geoLocationReducer, 'email': emailReducer, 'phone': phoneReducer, 
              'skills': skillsReducer, 'address': addressReducer,
              'employees': employeeReducer}), 
              provideEffects([EmailEffects, PhoneEffects, SkillEffects, AddressEffects, GeoLocationEffects, EmployeeEffects]),
              provideHttpClient(withFetch(), withJsonpSupport(), withInterceptors([])), provideNativeDateAdapter(),ApplicationInsightsService, AddressService, EmailService, PhoneService,
              AuthenticationService, GeoLocationService, !environment.production ? provideStoreDevtools({maxAge:25,  trace: false, traceLimit: 75, logOnly: !isDevMode() }) : []],
}; 
