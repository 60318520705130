import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UnAutorizedComponent } from './unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './page-not-found/pagenotfound.component';

export const routes: Routes = [
{
    path: '',
    title: 'Dashboard - Bright Light Security Services',
    component: DashboardComponent,

},
{
    path: 'skills',
    title: 'Skills - Bright Light Security Services',
    loadChildren: ()=> import('./skills/skills.module').then(s => s.SkillsModule),
    canLoad: []
},
{
    path: 'users',
    title: 'Users - Bright Light Security Services',
    loadChildren: () => import('./users/users.module').then(u => u.UsersModule),
    canLoad: []
},
{
    path: 'employee',
    title: 'Employees - Bright Light Security Services',
    loadChildren: () => import('./employees/employees.module').then(e => e.EmployeesModule),
    canLoad: []
},
{
    path: 'site',
    title: 'Sites - Bright Light Security Services',
    loadChildren: () => import('./sites/sites.module').then(s =>s.SitesModule),
    canLoad: []
},
{
    path: 'schedule',
    title: 'Schedule - Bright Light Security Services',
    loadChildren: ()=> import('./schedules/schedules.module').then(s => s.SchedulesModule),
    canLoad: []
},
{
    path: 'import',
    title: 'Import - Bright Light Security Services',
    loadChildren: ()=> import('./import/import.module').then(i => i.ImportModule),
}, 
{
    path: '**',
    title: 'Page Not Found - Bright Light Security Services',
    component: PageNotFoundComponent
}, 
{
    path: 'unauthorized', 
    title: 'UnAuthorize Access - Bright Light Security Services',
    component: UnAutorizedComponent}
];
@NgModule({
    imports:[
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule{}