<div *ngIf="showSplash()" class="container">
    <div class="splash-container">
        <div class="container-center-horizontal">
            <div class="splashscreen-web screen">
                <div class="layer-container">
                    <div class="layer-1 bp3-animate-enter"></div>
                    <div class="layer-2"></div>
                    <div class="layer-3 bp3-animate-enter1"></div>
                    <div class="layer-4 bp3-animate-enter2"></div>
                </div>
            </div>
        </div>
        <div class="container-center-horizontal">
            <div class="index screen">
                <div class="overlap-group1">
                    <div class="component-2-1">
                        <div class="layer-container-1">
                            <div class="layer-1 bp2-animate-enter"></div>
                            <div class="layer-2-1"></div>
                            <div class="layer-3 bp2-animate-enter1"></div>
                            <div class="layer-4 bp2-animate-enter2"></div>
                            <div class="layer-1 bp2-animate bp2-animate-enter3"></div>
                        </div>
                    </div>
                    <div class="layer-1 bp2-animate bp2-animate-enter4"></div>
                    <div class="layer-2-1"></div>
                    <div class="layer-3 bp2-animate-enter5"></div>
                    <div class="layer-4 bp2-animate-enter6"></div>
                    <div class="layer-1 bp2-animate bp2-animate-enter7"></div>
                </div>
            </div>
        </div>
        <div class="container-center-horizontal">
            <div class="splashscreen-mobile screen">
                <div class="layer-container-2">
                    <div class="layer-2-2"></div>
                    <div class="layer-3-3 bp1-animate-enter"></div>
                    <div class="layer-4-3 bp1-animate-enter1"></div>
                    <div class="layer-1-5 bp1-animate-enter2"></div>
                </div>
            </div>
        </div>
    </div>  
</div>