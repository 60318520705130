import { Injectable } from "@angular/core";
import {User, UserManager, UserManagerSettings} from "oidc-client"
import { Subject, from, lastValueFrom } from "rxjs";

@Injectable({
    'providedIn':'root'
})
export class AuthenticationService {

    private _userManager?: UserManager;
    private _user?: User | null
    private _userManagerSettings?: UserManagerSettings
    private _loggedInSubject = new Subject<boolean>();
    public isloggedIn = this._loggedInSubject.asObservable();
    constructor() {
        this._userManagerSettings = {
            client_id: '',
            authority: '',
            redirect_uri: '',
            scope: 'openid profile',
            response_type: 'code',
            post_logout_redirect_uri: '',
            automaticSilentRenew: true,
            silent_redirect_uri: ''


       }
        this._userManager = new UserManager(this._userManagerSettings)
        this._userManager.events.addAccessTokenExpired(_=> {
            this._loggedInSubject.next(false);
        })
    }
    login(){
        return this._userManager?.signinPopup()
    }
    completeLogin(){
        return this._userManager?.signinCallback("").then(user =>{
            this._user = user;
            this._loggedInSubject.next(!!user && !user.expired);
            return user;
        })
    }
    logout(){
        return this._userManager?.signoutRedirect()
    }
    completeLogout(){
        this._user = null;
        this._loggedInSubject.next(false);
        return this._userManager?.signoutRedirect();
    }
    isLoggedIn(){
        return this._userManager?.getUser().then(user => {
            const currentUser = !!user && !user.expired;
            if(this._user !==user){
                this._loggedInSubject.next(currentUser)
            }
            this._user = user;
            return currentUser;
        })
    }
    token(){
      return this._userManager?.getUser().then(user => {
            if(!!user && !user.expired){
                return user.access_token;
            }
            return '';
        }) as Promise<string>
    }
}
