import {createAction, createActionGroup, emptyProps, props} from '@ngrx/store'
export const GeolocationPermissionActions = createActionGroup({
    source: 'Navigation',
    events:{
        'Geo Location Permission Prompt': emptyProps()
    }
})

export const GeoLoactionPermissionAPIActions = createActionGroup({
    source: 'Geo Location Permission API',
    events:{
        'Geo Location Prompt Granted': props<{location: string }>(),
        'Geo Location Prompt Denied' : props<{message: string}>()
    }
})