import { createReducer, on } from "@ngrx/store";
import {SkillsPageActions, SkillsAPIActions} from "../actions/skill.action";
import { ISkill } from "../../models/skill";

export interface IResultSkillState{
    skills: ISkill[],
    loading: boolean,
    errorMessage:string,
    addedSkill:boolean, 
    deletedSkill: boolean,
    updatedSkill:boolean,
    skillsAdded: ISkill[]
}
export const initialState : IResultSkillState = {
    skills:[],
    loading: false,
    errorMessage: '',
    addedSkill: false,
    deletedSkill:false,
    updatedSkill:false,
    skillsAdded : []
};

export const skillsReducer = createReducer(
    initialState,
    on(SkillsPageActions.loadSkills, (state)=>({
        ...state,
        loading: true,
        skills: [],
        errorMessage: ''
    })),
    on(SkillsAPIActions.skillsLoadedSuccess, (state, {skills})=>({
        ...state,
        loading: false,
        skills: skills,
        errorMessage: ''
    })),
    on(SkillsAPIActions.skillsLoadedFail, (state, {message})=> ({
        ...state,
        loading: false,
        errorMessage: message,
    })),
    on(SkillsPageActions.addSkill, (state)=>({
        ...state,
        loading: true,
        errorMessage: '',
        addedSkill: false
    })),
    on(SkillsAPIActions.skillAddedSuccess, (state, {skill})=> ({
        ...state,
        loading: false,
        skills : [...state.skills as ISkill[], skill],
        addedSkill: true
    })),
    on(SkillsAPIActions.skillAddedFail, (state, {message})=>({
        ...state,
        loading: false,
        errorMessage: message,
        updatedSkill:false
    })),
    on(SkillsPageActions.updateSkill, (state)=>({
        ...state,
        loading: true,
        errorMessage: '',
        updatedSkill: false,
    })),
    on(SkillsAPIActions.skillUpdatedSuccess, (state, {skill})=>({
        ...state,
        loading:false,
        skills : state.skills.map((existingEmployee)=> existingEmployee.id == skill.id ? skill : existingEmployee),
        updatedSkill: true
    })),
    on(SkillsAPIActions.skillUpdatedFailed, (state, {message})=>({
        ...state,
        loading: false,
        errorMessage: message,
        updatedSkill:false
    })),
    on(SkillsPageActions.deleteSkill, (state)=>({
        ...state,
        loading:true,
        errorMessage:'',
        deletedSkill: false
    })),
    on(SkillsAPIActions.skillDeletedSuccess, (state, {skillId})=>({
        ...state,
        loading: false,
        skills : state.skills.filter(skill => skill.id != skillId),
        deletedSkill: true

    })),
    on(SkillsAPIActions.skillDeletedFail, (state, {message})=>({
        ...state,
        loading: false,
        errorMessage: message,
        deletedSkill: false
    })),
    on(SkillsPageActions.addSkills, (state)=>({
        ...state,
        loading: true,
        errorMessage: ''
    })),
    on(SkillsAPIActions.skillsAddedSuccess, (state, {skills})=>({
        ...state,
        loading: false,
        skillsAdded: skills,
        errorMessage: ''
    })),
    on(SkillsAPIActions.skillsAddedFail, (state, {message})=> ({
        ...state,
        loading: false,
        errorMessage: message,
    })),
)
