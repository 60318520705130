import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { PhoneVerificationAPIActions, PhoneVerificationActions } from "../actions/phone.action";
import { PhoneService } from "../../services/shared/phone-service";

@Injectable()
export class PhoneEffects {

    email$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PhoneVerificationActions.phoneNumberVerification),
            concatMap(({ phoneNumber }) =>
                this._phoneService.verifyPhoneNumber(phoneNumber).pipe(
                    map((phone) =>
                    {
                        const resultObject = JSON.parse(JSON.stringify(phone))
                        if(resultObject?.phone_number == null || '') {
                            throw new HttpErrorResponse({statusText : phoneNumber + " not valid!"})
                        }
                        const resultParsed = {"phoneNumber" : resultObject.phone_number}                      
                        return PhoneVerificationAPIActions.phoneVerificationSuccess({ phone: phone})
                    }   
                    ),
                    catchError((error: HttpErrorResponse) => [PhoneVerificationAPIActions.phoneVerificationFail({ message: "Error while Validating Phone Number!" })])
                )
            )))

    constructor(
        private actions$: Actions,
        private _phoneService: PhoneService
    ) {
    }

}