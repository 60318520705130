import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AddressService } from "../../services/shared/address-service";
import { AddressAutoCompleteActions, AddressAutoCompleteApiActions } from "../actions/address.action";
import { catchError, concatMap, map } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class AddressEffects {

    autoComplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddressAutoCompleteActions.addressAutoComplete),
            concatMap(({ searchTerm }) =>
                this._addressService.placeAutoComplete(searchTerm).pipe(
                    map((placeAutoComplete) =>
                        AddressAutoCompleteApiActions.addressAutoCompleteSuccess({ placeAutoComplete: placeAutoComplete })),
                    catchError((error: HttpErrorResponse) => [AddressAutoCompleteApiActions.addressAutoCompleteFail({ message: String(String(error.error)) })])
                )
            )))

    address$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddressAutoCompleteActions.addressGeoCode),
            concatMap(({ index, geocode }) =>
                this._addressService.placeGeoCode(geocode, index).pipe(
                    map((address) => AddressAutoCompleteApiActions.addressGeoCodeSuccess({ address: address }),
                        catchError((error: HttpErrorResponse) => [AddressAutoCompleteApiActions.addressGeoCodeFail({ message: String(error.error) })])
                    )
                ))))

    addresses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddressAutoCompleteActions.addressGeoCodes),
            concatMap(({ gecodes }) =>
                this._addressService.placeGeoCodes(gecodes).pipe(
                    map((addresses) => AddressAutoCompleteApiActions.addressesGeoCodeSuccess({ addresses: addresses }),
                        catchError((error: HttpErrorResponse) => [AddressAutoCompleteApiActions.addressesGeoCodeFail({ message: String(error.error) })])
                    )
                ))))

                addressesEmployee$ = createEffect(() =>
                    this.actions$.pipe(
                        ofType(AddressAutoCompleteActions.employeesAddressGeoCodes),
                        concatMap(({ gecodes }) =>
                            this._addressService.placeGeoCodes(gecodes).pipe(
                                map((addresses) => AddressAutoCompleteApiActions.employeeAddressesGeoCodeSuccess({ addresses: addresses }),
                                    catchError((error: HttpErrorResponse) => [AddressAutoCompleteApiActions.employeeAddressesGeoCodeFail({ message: String(error.error) })])
                                )
                            ))))

    addressesSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddressAutoCompleteActions.sitesAddressGeoCodes),
            concatMap(({ gecodes }) =>
                this._addressService.placeGeoCodes(gecodes).pipe(
                    map((addresses) => AddressAutoCompleteApiActions.siteAddressesGeoCodeSuccess({ addresses: addresses }),
                        catchError((error: HttpErrorResponse) => [AddressAutoCompleteApiActions.siteAddressesGeoCodeFail({ message: String(error.error) })])
                    )
                ))))


    constructor(
        private actions$: Actions,
        private _addressService: AddressService
    ) {
    }

}