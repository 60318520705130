import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { SplashScreenPageActions } from "../ngrx-state/actions/splashscreen.action";
import { selectHideSplashScreen } from "../ngrx-state/selectors/splashscreen.selector";

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class DashboardComponent implements OnInit{

    showMenu$ = this._store.select(selectHideSplashScreen)
    constructor(private _store: Store
    ){       
    }

    ngOnInit(): void {
        this._store.dispatch(SplashScreenPageActions.showSplashScreen());    
    }
}