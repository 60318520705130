import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GeoLoactionPermissionAPIActions, GeolocationPermissionActions } from "../actions/geolocation.action";
import { catchError, map, mergeMap } from "rxjs";
import { GeoLocationService } from "../../services/shared/geolocation-service";


@Injectable()
export class GeoLocationEffects{
    geoLocation$ = createEffect(()=>
        this._actions$.pipe(
            ofType(GeolocationPermissionActions.geoLocationPermissionPrompt),
            mergeMap(()=>
            this._geoLocation.enableLocationRequest().pipe(
                map((location) => GeoLoactionPermissionAPIActions.geoLocationPromptGranted({location})),
                catchError((error: any) => [GeoLoactionPermissionAPIActions.geoLocationPromptDenied({message:error})]
            )
        )
    )))

    constructor(
        private _actions$: Actions,
        private _geoLocation: GeoLocationService
    ) {
        
    }
}