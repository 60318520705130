import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IEnforceState } from "../reducers/splashscreen.reducer";


export const selectEnforceState = createFeatureSelector<IEnforceState>('enforce');

export const selectShowSplashScreen = createSelector(
    selectEnforceState,
    ({showSplashScreen}) => showSplashScreen
);

export const selectHideSplashScreen =  createSelector(
    selectEnforceState,
    ({showSplashScreen}) => showSplashScreen
);