import {createActionGroup, emptyProps, props} from '@ngrx/store'

export const EmailVerificationAction = createActionGroup({
    source: 'Email Verification',
    events:{
        'Email Verification': props<{emailAddress: string}>(),
    }
})

export const EmailVerificationActionAPI = createActionGroup({
    source: 'Email Verification API',
    events:{
        'Email Verification Success': props<{email: any }>(),
        'Email Verification Fail' : props<{message: string}>()
    }
})