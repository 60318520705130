import { createReducer, on } from "@ngrx/store";
import { PhoneVerificationAPIActions, PhoneVerificationActions } from "../actions/phone.action";
import { IPhone } from "../../models/shared/phoneNumber";

export interface IResultPhoneState{
    phone: IPhone,
    loading: boolean,
    errorMessage: string,
    phones: IPhone[],
    employeephones: IPhone[],
    sitephones: IPhone[]
}

export const initialState : IResultPhoneState = {
    phone: {id:0, phoneNumber: '', callerName: '', carrierType:'', countryCode:'', nationalFormat:''},
    loading:false,
    errorMessage: '',
    phones: [],
    employeephones: [],
    sitephones: []
}

export const phoneReducer = createReducer(
    initialState,
    on(PhoneVerificationActions.phoneNumberVerification, (state)=>({
        ...state,
        loading:true,
        errorMessage: ''
    })),
    on(PhoneVerificationAPIActions.phoneVerificationSuccess, (state, {phone})=>({
        ...state,
        loading:false,
        phone: phone,
        errorMessage: ''
    })),
    on(PhoneVerificationAPIActions.phoneVerificationFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    })),
    on(PhoneVerificationActions.phoneNumbersVerification, (state)=>({
        ...state,
        loading:true,
        errorMessage: '',
        phones: []
    })),
    on(PhoneVerificationAPIActions.phonesVerificationSuccess, (state, {phones})=>({
        ...state,
        loading:false,
        phones: phones,
        errorMessage: ''
    })),
    on(PhoneVerificationAPIActions.phonesVerificationFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    })),
    on(PhoneVerificationActions.employeePhoneNumbersVerification, (state)=>({
        ...state,
        loading:true,
        errorMessage: '',
        employeephones: []
    })),
    on(PhoneVerificationAPIActions.employeePhonesVerificationSuccess, (state, {phones})=>({
        ...state,
        loading:false,
        employeephones: phones,
        errorMessage: ''
    })),
    on(PhoneVerificationAPIActions.employeePhonesVerificationFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    })),
    on(PhoneVerificationActions.sitePhoneNumbersVerification, (state)=>({
        ...state,
        loading:true,
        errorMessage: '',
        sitephones: []
    })),
    on(PhoneVerificationAPIActions.sitePhonesVerificationSuccess, (state, {phones})=>({
        ...state,
        loading:false,
        sitephones: phones,
        errorMessage: ''
    })),
    on(PhoneVerificationAPIActions.sitePhonesVerificationFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    }))
)