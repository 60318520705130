import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { IEmail } from "../../models/shared/email";

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    private teamAdvanceCommunicationApi = environment.enforceApiUrl;
    private options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), params: new HttpParams(),  webFetchExtra: { mode: 'no-cors' }  };
    constructor(private _httpClient: HttpClient) {
    }

    verifyEmail(emailAddress: string, index:number) {
        return this._httpClient.get<any>(this.teamAdvanceCommunicationApi + "communication/api/v2/" + "email/check/" + emailAddress  + '/' + index, this.options);
    }

    verifyEmails(emails: IEmail[]) {
        return this._httpClient.post<IEmail[]>(this.teamAdvanceCommunicationApi + "communication/api/v2/" + "email/check", emails, this.options);
    }
}