import { createReducer, on } from "@ngrx/store";
import {EmployeePageActions, EmployeesAPIActions} from "../actions/employee.action";
import { IEmployee } from "../../models/employee";
import { IAddress } from "../../models/address";

export interface IResultEmployeeState{
    employees: IEmployee[],
    selectedEmployeeId : string,
    selectedEmployee: IEmployee,
    loading: boolean,
    errorMessage: string,
    showDeleteConfirmation: boolean
    employeeAdded: boolean,
    employeeUpdated: boolean,
    employeeDeleted: boolean
}

export const initialState : IResultEmployeeState= {
    employees: [],
    selectedEmployeeId : '',
    selectedEmployee: {
        id: '', employeeId: 0, firstName: '', lastName: '', role:'', avatar: '', phoneNumber: '',
        emailAddress: '', address: {id: 0, placeId: '', streetAddress1: '', streetAddress2: '', city:'', state:'', zipCode: '', zipCodeExtension: '', location : {latitude:0, longitude:0}    }, 
        availabilities: [], isActive: true,
        organizationId: '', requiredSkills: [], hireDate: new Date()
    },
    loading: false,
    errorMessage: '',
    showDeleteConfirmation: false,
    employeeAdded: false,
    employeeUpdated: false,
    employeeDeleted: false
};

export const employeeReducer = createReducer(
    initialState,
    on(EmployeePageActions.loadEmployees, (state)=>({
        ...state,
        employees: [],
        loading:true,
        errorMessage: ''
    })),
    on(EmployeesAPIActions.employeesLoadedSuccess, (state, {employees})=> ({
        ...state,
        employees: employees,
        loading:false,
        errorMessage: ''
    })),
    on(EmployeesAPIActions.employeesLoadedFail, (state, {message})=> ({
        ...state,
        employees: [],
        loading:false,
        errorMessage: message
    })),
    on(EmployeePageActions.selectedEmployee, (state, {employeeId})=>({
        ...state,
        selectedEmployeeId: employeeId,
        loading:true,
        errorMessage: ''
    })),
    on(EmployeesAPIActions.employeeByIDSuccess, (state, {employee})=> ({
        ...state,
        selectedEmployee: employee,
        selectedEmployeeId: state.selectedEmployee.id,
        loading:false,
        errorMessage: ''
    })),
    on(EmployeesAPIActions.employeeByIDFail, (state, {message})=> ({
        ...state,
        loading:false,
        errorMessage: message
    })),
    on(EmployeePageActions.addEmployee, (state) => ({
        ...state,
        loading:true,
        errorMessage: '',
        employeeAdded:false
    })),
    on(EmployeesAPIActions.employeeAddSuccess, (state, {employee})=>({
        ...state,
        loading:false,
        employees: [...state.employees, employee],
        employeeAdded: true
    })),
    on(EmployeesAPIActions.employeeAddFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message,
        employeeAdded:false
    })),
    on(EmployeePageActions.updateEmployee, (state)=>({
        ...state,
        loading:true,
        errorMessage: '',
        employeeUpdated: false
    })),
    on(EmployeesAPIActions.employeeUpdateSuccess, (state, {employee})=>({
        ...state,
        loading: false,
        employees: state.employees.map((existingEmployee)=>
         existingEmployee.id == employee.id ? employee : existingEmployee),
        employeeUpdated: true
    })),
    on(EmployeesAPIActions.employeeUpdateFail, (state, {message})=>({
        ...state,
        loading: false,
        errorMessage: message,
        employeeUpdated: false
    })),
    on(EmployeePageActions.deleteConfirmation, (state)=>({
        ...state,
        showDeleteConfirmation:true,
        errorMessage: '',
        loading:false,
    })),
    on(EmployeePageActions.deleteYes, (state)=>({
        ...state,
        showDeleteConfirmation:false,
        errorMessage: '',
        loading:false,
    })),
    on(EmployeePageActions.deleteNo, (state)=>({
        ...state,
        showDeleteConfirmation:false,
        errorMessage: '',
        loading:false,
    })),
    on(EmployeePageActions.deleteEmployee, (state)=>({
        ...state,
        loading:true,
        errorMessage: ''
    })),
    on(EmployeesAPIActions.employeeDeleteSuccess, (state, {employeeId})=>({
        ...state,
        loading:false,
        employees : state.employees.filter((existingEmployee)=>
            existingEmployee.id != employeeId),
        employeeDeleted: true
    })),
    on(EmployeesAPIActions.employeeDeleteFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    })),
    on(EmployeePageActions.addEmployees, (state)=>({
        ...state,
        employees: [],
        loading:true,
        errorMessage: ''
    })),
    on(EmployeesAPIActions.employeesAddSuccess, (state, {employees})=> ({
        ...state,
        employees: employees,
        loading:false,
        errorMessage: ''
    })),
    on(EmployeesAPIActions.employeesAddFail, (state, {message})=> ({
        ...state,
        employees: [],
        loading:false,
        errorMessage: message
    }))

)
