import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EmployeeService } from "../../services/employee-service";
import { catchError, concatMap, map, mergeMap } from "rxjs";
import { EmployeePageActions, EmployeesAPIActions } from "../actions/employee.action";
import { HttpErrorResponse } from "@angular/common/http";
import { toSignal } from "@angular/core/rxjs-interop";
import { IEmployee } from "../../models/employee";
import { AddressService } from "../../services/shared/address-service";

@Injectable()
export class EmployeeEffects {
    employees$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeePageActions.loadEmployees),
            mergeMap(() =>
                this._employeeService.employees$.pipe(
                    map(employees => 
                        {
                            this._employeeService.employees.set(employees);
                            return EmployeesAPIActions.employeesLoadedSuccess({ employees })}),
                    catchError((error: HttpErrorResponse) => [EmployeesAPIActions.employeesLoadedFail({ message: String(error.error) })]))
            )
        )
    )

    employee$ = createEffect(()=>
        this.actions$.pipe(
            ofType(EmployeePageActions.selectedEmployee),
            mergeMap(({employeeId}) =>
                this._employeeService.getByEmployeeId(employeeId).pipe(
                    map(employee => {                        
                        return EmployeesAPIActions.employeeByIDSuccess({ employee })
                    }),
                    catchError((error: HttpErrorResponse) => [EmployeesAPIActions.employeesLoadedFail({ message: String(error.error) })]))
            )
        )
    )

    addEmployee$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeePageActions.addEmployee),
            concatMap(({ employee }) =>
               this._employeeService.addEmployee(employee).pipe(
                    map((newEmployee) => {
                      this._employeeService.addedEmployee.update(() => newEmployee);
                      return  EmployeesAPIActions.employeeAddSuccess({ employee: newEmployee })
                    }),
                    catchError((error: HttpErrorResponse) => [EmployeesAPIActions.employeeAddFail({ message: String(error.error) })])
                )
            )
        ))
    

    updateEmployee$ = createEffect(()=>
        this.actions$.pipe(
            ofType(EmployeePageActions.updateEmployee),
            concatMap(({employee})=>
                this._employeeService.updateEmployee(employee).pipe(
                    map((updatedEmployee)=>
                    EmployeesAPIActions.employeeUpdateSuccess({employee: updatedEmployee})),
                    catchError((error: HttpErrorResponse) => [EmployeesAPIActions.employeeUpdateFail({message:String(error.error)})])
                )
            )
        )
    )

    deleteEmployee$ = createEffect(()=>
    this.actions$.pipe(
        ofType(EmployeePageActions.deleteEmployee),
        concatMap(({employeeId})=> 
        this._employeeService.deleteEmployee(employeeId).pipe(
            map(() => EmployeesAPIActions.employeeDeleteSuccess({employeeId}),
        catchError((error: HttpErrorResponse)=>[EmployeesAPIActions.employeeDeleteFail({message : String(error.error)})])
        )
    ))))

    addEmployees$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeePageActions.addEmployees),
            concatMap(({ employees }) =>
               this._employeeService.addEmployees(employees).pipe(
                    map((newEmployees) => EmployeesAPIActions.employeesAddSuccess({ employees: newEmployees })),
                    catchError((error: HttpErrorResponse) => [EmployeesAPIActions.employeesAddFail({ message: String(error.error) })])
                )
            )
        ))

    

    constructor(
        private actions$: Actions,
        private _employeeService: EmployeeService
    ) {
      
    }


}