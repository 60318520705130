import { Component } from "@angular/core";

@Component({
    selector:'pagenotfound',
    standalone: true,
    templateUrl: './pagenotfound.component.html',
    styleUrls: ['./pagenotfound.component.scss']

})

export class PageNotFoundComponent {

}