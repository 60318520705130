import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { PhoneVerificationAPIActions, PhoneVerificationActions } from "../actions/phone.action";
import { PhoneService } from "../../services/shared/phone-service";

@Injectable()
export class PhoneEffects {

    phone$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PhoneVerificationActions.phoneNumberVerification),
            concatMap(({ phoneNumber, index }) =>
                this._phoneService.verifyPhoneNumber(phoneNumber, index).pipe(
                    map((phone) => PhoneVerificationAPIActions.phoneVerificationSuccess({ phone: phone })
                    ),
                    catchError((error: HttpErrorResponse) => [PhoneVerificationAPIActions.phoneVerificationFail({ message: "Error while Validating Phone Number!" })])
                )
            )))

    phones$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PhoneVerificationActions.phoneNumbersVerification),
            concatMap(({ phoneNumbers }) =>
                this._phoneService.verifyPhoneNumbers(phoneNumbers).pipe(
                    map((phones) => PhoneVerificationAPIActions.phonesVerificationSuccess({ phones: phones })
                    ),
                    catchError((error: HttpErrorResponse) => [PhoneVerificationAPIActions.phonesVerificationFail({ message: "Error while Validating Phone Number!" })])
                )
            )))

    phonesEmployee$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PhoneVerificationActions.employeePhoneNumbersVerification),
            concatMap(({ phoneNumbers }) =>
                this._phoneService.verifyPhoneNumbers(phoneNumbers).pipe(
                    map((phones) => PhoneVerificationAPIActions.employeePhonesVerificationSuccess({ phones: phones })
                    ),
                    catchError((error: HttpErrorResponse) => [PhoneVerificationAPIActions.employeePhonesVerificationFail({ message: "Error while Validating Phone Number!" })])
                )
            )))

    phonesSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PhoneVerificationActions.sitePhoneNumbersVerification),
            concatMap(({ phoneNumbers }) =>
                this._phoneService.verifyPhoneNumbers(phoneNumbers).pipe(
                    map((phones) => PhoneVerificationAPIActions.sitePhonesVerificationSuccess({ phones: phones })
                    ),
                    catchError((error: HttpErrorResponse) => [PhoneVerificationAPIActions.sitePhonesVerificationFail({ message: "Error while Validating Phone Number!" })])
                )
            )))

    constructor(
        private actions$: Actions,
        private _phoneService: PhoneService
    ) {
    }

}