import { createReducer, on } from "@ngrx/store";
import { EmailVerificationAction, EmailVerificationActionAPI } from "../actions/email.action";
import { IEmail } from "../../models/shared/email";

export interface IResultEmailState{
    email: IEmail,
    loading: boolean,
    errorMessage: string,
    emails: IEmail[],
    siteEmails: IEmail[],
    employeeEmails: IEmail[]
}

export const initialState : IResultEmailState = {
    email: {id:0, verdict:'', emailAddress:''},
    loading:false,
    errorMessage: '',
    emails: [],
    siteEmails: [],
    employeeEmails: []
}

export const emailReducer = createReducer(
    initialState,
    on(EmailVerificationAction.emailVerification, (state)=>({
        ...state,
        loading:true,
        errorMessage: ''
    })),
    on(EmailVerificationActionAPI.emailVerificationSuccess, (state, {email})=>({
        ...state,
        loading:false,
        email: email,
        errorMessage: ''
    })),
    on(EmailVerificationActionAPI.emailVerificationFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    })),
    on(EmailVerificationAction.emailsVerification, (state)=>({
        ...state,
        loading:true,
        errorMessage: ''
    })),
    on(EmailVerificationActionAPI.emailsVerificationSuccess, (state, {emails})=>({
        ...state,
        loading:false,
        emails: emails,
        errorMessage: ''
    })),
    on(EmailVerificationActionAPI.emailsVerificationFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    })),
    on(EmailVerificationAction.employeeEmailsVerification, (state)=>({
        ...state,
        loading:true,
        errorMessage: ''
    })),
    on(EmailVerificationActionAPI.employeeEmailsVerificationSuccess, (state, {emails})=>({
        ...state,
        loading:false,
        employeeEmails: emails,
        errorMessage: ''
    })),
    on(EmailVerificationActionAPI.employeeEmailsVerificationFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    })),
    on(EmailVerificationAction.siteEmailsVerification, (state)=>({
        ...state,
        loading:true,
        errorMessage: ''
    })),
    on(EmailVerificationActionAPI.siteEmailsVerificationSuccess, (state, {emails})=>({
        ...state,
        loading:false,
        siteEmails: emails,
        errorMessage: ''
    })),
    on(EmailVerificationActionAPI.siteEmailsVerificationFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message
    }))
)