import { createReducer, on } from "@ngrx/store";
import { GeoLoactionPermissionAPIActions, GeolocationPermissionActions } from "../actions/geolocation.action";
import { state } from "@angular/animations";

export interface IResultGeoLocationState{
    location: undefined,
    loading: boolean,
    errorMessage: string,
}

export const initialState : IResultGeoLocationState = {
    location: undefined,
    loading:false,
    errorMessage: ''
}

export const geoLocationReducer = createReducer(
    initialState,
    on(GeolocationPermissionActions.geoLocationPermissionPrompt, (state)=>({
        ...state,
        loading:false,
        location: undefined,
        errorMessage: ''
    })),
    on(GeoLoactionPermissionAPIActions.geoLocationPromptGranted, (state, {location})=>({
        ...state,
        loading:false,
        location: undefined,
        errorMessage: ''
    })),
    on(GeoLoactionPermissionAPIActions.geoLocationPromptDenied, (state, {message})=>({
        ...state,
        loading:false,
        location: undefined,
        errorMessage: message
    }))
)