import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const SplashScreenPageActions = createActionGroup({
    source: 'Welcome Page',
    events: {
        'Show SplashScreen': emptyProps(),
        'Hide SplashScreen': emptyProps(),
    }
}
)

