import { Component, effect, Inject } from "@angular/core";
import { ISkill } from "../../models/skill";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule } from "@angular/forms";
import { Store } from "@ngrx/store";
import { SkillsPageActions } from "../../ngrx-state/actions/skill.action";
import { SkillService } from "../../services/skill-service";
import { selectAddedSkillSuccess } from "../../ngrx-state/selectors/skill.selector";
import { toSignal } from "@angular/core/rxjs-interop";

@Component({
    selector: 'skill-new',
    templateUrl: './skill.new.component.html',
    styleUrls : ['./skill.new.component.scss'],
    imports: [MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatFormFieldModule, FormsModule],
    standalone: true
})

export class SkillNewComponent{
    addedSkill$ = this._store.select(selectAddedSkillSuccess)
    
    constructor(
        public dialogRef: MatDialogRef<SkillNewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ISkill,
        private _store: Store,
        private _skillService: SkillService
    ) {
        
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
    onSaveSkill(data: ISkill){
        this._store.dispatch(SkillsPageActions.addSkill({skill:data}));      
        this.dialogRef.close();
        this._skillService.addedSkill.update(() => data);
        this._skillService.isSkillAdded.update(() => toSignal(this.addedSkill$, {initialValue:false})());    
    }

}