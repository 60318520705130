import { createReducer, on } from "@ngrx/store";
import { IPlaceAutoComplete } from "../../models/placeAutoComplete";
import { IAddress } from "../../models/address";
import { AddressAutoCompleteActions, AddressAutoCompleteApiActions } from "../actions/address.action";

export interface IResultAddressState{
    predictions: IPlaceAutoComplete[],
    address: IAddress[],
    loading: boolean,
    errorMessage: string,
}

export const initialState : IResultAddressState = {
    predictions: [],
    address : [],
    loading: false,
    errorMessage: ''
};

export const addressReducer = createReducer(
    initialState,
    on(AddressAutoCompleteActions.addressAutoComplete, (state)=>({
        ...state,
        loading: false,
        errorMessage: ''
    })),
    on(AddressAutoCompleteApiActions.addressAutoCompleteSuccess, (state, {placeAutoComplete})=>({
        ...state,
        loading:false,
        errorMessage:'',
        predictions: placeAutoComplete
    })),
    on(AddressAutoCompleteApiActions.addressAutoCompleteFail, (state, {message})=>({
        ...state,
        loading: false,
        errorMessage: message,
        predictions: []
    })),
    on(AddressAutoCompleteActions.addressGeoCode, (state)=>({
        ...state,
        loading: false,
        errorMessage:'',
        predictions: []
    })),
    on(AddressAutoCompleteApiActions.addressGeoCodeSuccess, (state, {address})=>({
        ...state,
        loading:false,
        errorMessage:'',
        address: address,
        predictions: []
    })),
    on(AddressAutoCompleteApiActions.addressGeoCodeFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message,
        predictions: [],
        address: []
    }))
)