import { createReducer, on } from "@ngrx/store";
import { IPlaceAutoComplete } from "../../models/placeAutoComplete";
import { IAddress } from "../../models/address";
import { AddressAutoCompleteActions, AddressAutoCompleteApiActions } from "../actions/address.action";

export interface IResultAddressState{
    predictions: IPlaceAutoComplete[],
    address: IAddress[],
    loading: boolean,
    errorMessage: string,
    addressses: Map<string, IAddress[]>,
    employeeAddressses: Map<string, IAddress[]>,
    siteAddressses: Map<string, IAddress[]>,
}

export const initialState : IResultAddressState = {
    predictions: [],
    address : [],
    loading: false,
    errorMessage: '',
    addressses: new Map<string, IAddress[]>(),
    employeeAddressses: new Map<string, IAddress[]>(),
    siteAddressses: new Map<string, IAddress[]>()
};

export const addressReducer = createReducer(
    initialState,
    on(AddressAutoCompleteActions.addressAutoComplete, (state)=>({
        ...state,
        loading: false,
        errorMessage: ''
    })),
    on(AddressAutoCompleteApiActions.addressAutoCompleteSuccess, (state, {placeAutoComplete})=>({
        ...state,
        loading:false,
        errorMessage:'',
        predictions: placeAutoComplete
    })),
    on(AddressAutoCompleteApiActions.addressAutoCompleteFail, (state, {message})=>({
        ...state,
        loading: false,
        errorMessage: message,
        predictions: []
    })),
    on(AddressAutoCompleteActions.addressGeoCode, (state)=>({
        ...state,
        loading: false,
        errorMessage:'',
        predictions: []
    })),
    on(AddressAutoCompleteApiActions.addressGeoCodeSuccess, (state, {address})=>({
        ...state,
        loading:false,
        errorMessage:'',
        address: address,
        predictions: []
    })),
    on(AddressAutoCompleteApiActions.addressGeoCodeFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message,
        predictions: [],
        address: []
    })),
    on(AddressAutoCompleteActions.addressGeoCodes, (state)=>({
        ...state,
        loading: true,
        errorMessage:''
    })),
    on(AddressAutoCompleteApiActions.addressesGeoCodeSuccess, (state, {addresses})=>({
        ...state,
        loading:false,
        errorMessage:'',
        addressses: addresses
    })),
    on(AddressAutoCompleteApiActions.addressesGeoCodeFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message,
    })),
    on(AddressAutoCompleteActions.sitesAddressGeoCodes, (state)=>({
        ...state,
        loading: true,
        errorMessage:''
    })),
    on(AddressAutoCompleteApiActions.siteAddressesGeoCodeSuccess, (state, {addresses})=>({
        ...state,
        loading:false,
        errorMessage:'',
        siteAddressses: addresses
    })),
    on(AddressAutoCompleteApiActions.siteAddressesGeoCodeFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message,
    })),
    on(AddressAutoCompleteActions.employeesAddressGeoCodes, (state)=>({
        ...state,
        loading: true,
        errorMessage:''
    })),
    on(AddressAutoCompleteApiActions.employeeAddressesGeoCodeSuccess, (state, {addresses})=>({
        ...state,
        loading:false,
        errorMessage:'',
        employeeAddressses: addresses
    })),
    on(AddressAutoCompleteApiActions.employeeAddressesGeoCodeFail, (state, {message})=>({
        ...state,
        loading:false,
        errorMessage: message,
    }))
)