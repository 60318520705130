import {createActionGroup, props} from '@ngrx/store'

export const PhoneVerificationActions = createActionGroup({
    source: 'Phone Verification',
    events:{
        'Phone Number Verification': props<{phoneNumber:string}>()
    }
})

export const PhoneVerificationAPIActions = createActionGroup({
    source: 'Phone Verification API',
    events:{
        'Phone Verification Success': props<{phone: any }>(),
        'Phone Verification Fail' : props<{message: string}>()
    }
})