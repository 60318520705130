import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    private teamAdvanceCommunicationApi = environment.teamAdvanceCommunicationApi;
    private options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), params: new HttpParams(),  webFetchExtra: { mode: 'no-cors' }  };
    constructor(private _httpClient: HttpClient) {
    }

    verifyEmail(emailAddress: string) {
        return this._httpClient.get<any>(this.teamAdvanceCommunicationApi + "v1/email/check/" + emailAddress, this.options);
    }



}