<div class="container">
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
      <h5 mat-dialog-title>Menu</h5>
<mat-dialog-content>
    <div class="d-flex flex-column mb-3">
        <div class="p-2">
            <mat-chip-listbox class="mat-mdc-chip-set-stacked">
                <mat-chip 
                   *ngFor="let menu of menus" (click)="onAddClick(menu)"
                   color="menu.color">
                    <img matChipAvatar src="{{menu.img}}"/>
                    {{menu.name}}
                </mat-chip>
            </mat-chip-listbox>
        </div>
    </div>
</mat-dialog-content>

</div>