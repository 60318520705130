import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { map, of, tap } from "rxjs";
import { IPhone } from "../../models/shared/phoneNumber";

@Injectable({
    providedIn: 'root'
})
export class PhoneService {

    private teamAdvanceCommunicationApi = environment.enforceApiUrl;
    private options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), params: new HttpParams(), webFetchExtra: { mode: 'no-cors' }  };
    constructor(private _httpClient: HttpClient) {
    }

    verifyPhoneNumber(phoneNumber: string, index: number) {
        return this._httpClient.get<IPhone>(this.teamAdvanceCommunicationApi + "communication/api/v1/" + "sms/check/" + phoneNumber + '/' + index, this.options);
    }

    verifyPhoneNumbers(phoneNumbers: IPhone[]) {
        return this._httpClient.post<IPhone[]>(this.teamAdvanceCommunicationApi + "communication/api/v1/"  + "sms/check", phoneNumbers, this.options);
    }



}