import { Injectable } from "@angular/core";
import { from, of } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class GeoLocationService{


    constructor() {
        
    }

    enableLocationRequest(){
        // var currentLocation = navigator.geolocation.getCurrentPosition() geolocation.enableLocationRequest(true, true)
        //            .then(()=>{
        //            return  geolocation.getCurrentLocation({desiredAccuracy: CoreTypes.Accuracy.any, updateDistance: 1, maximumAge: 5000, timeout: 20000 })
        //             .then((currentLocation)=> {return currentLocation})
        //            })
        // return from(currentLocation)
        return from('')
    }
}